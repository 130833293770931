export const quiz = {
    questions: [
      {
        id: 1,
        type: "radio",
        question: "We __ American.",
        answers: [
          { answer: "aren’t", correct: "true" },
          { answer: "not are"},
          { answer: "not"},
          { answer: "isn’t"  },
          { answer: "I don’t know"},
        ],
      },
      {
        id: 2,
        type: "radio",
        question: "___ this magazine before?",
        answers: [
          { answer: "Do you read" },
          { answer: "Are you going to read" },
          { answer: "Are you reading"},
          { answer: "Have you read", correct: "true" },

          { answer: "I don’t know"},
        ],
      },
      {
        id: 3,
        type: "radio",
        question: "This is our new teacher. ___ name is Mark.",
        answers: [
          { answer: "Her" },
          { answer: "Its"},
          { answer: "He"},
          { answer: "His" , correct: "true" },
          { answer: "I don’t know"},
        ],
      },
      {
        id: 4,
        type: "radio",
        question: "He ______ the newspaper every day.",
        answers: [
          { answer: "read" },
          { answer: "reads" , correct: "true"},
          { answer: "doesn’t reads"},
          { answer: "don’t reads" },
          { answer: "I don’t know"},
        ],
      },
      {
        id: 5,
        type: "radio",
        question: "Is Mont Blanc ___ mountain in Europe?",
        answers: [
          { answer: "the higher" },
          { answer: "the most highest"},
          { answer: "the more high"},
          { answer: "the highest" , correct: "true" },
          { answer: "I don’t know"},
        ],
      },
      {
        id: 6,
        type: "radio",
        question: "British people ___ tea with milk.",
        answers: [
          { answer: "to drink" },
          { answer: "drink" , correct: "true"},
          { answer: "drinks"},
          { answer: "are drink" },
          { answer: "I don’t know"},
        ],
      },
      {
        id: 7,
        type: "radio",
        question: "___ you like Chinese food?",
        answers: [
          { answer: "Do", correct: "true" },
          { answer: "Does"},
          { answer: "Are"},
          { answer: "Is"  },
          { answer: "I don’t know"},
        ],
      },
      {
        id: 8,
        type: "radio",
        question: "It’s my ___ computer.",
        answers: [
          { answer: "parents" },
          { answer: "parents’", correct: "true"},
          { answer: "parent"},
          { answer: "parent’s"  },
          { answer: "I don’t know"},
        ],
      },
      {
        id: 9,
        type: "radio",
        question: "Could we ___ the bill, please?",
        answers: [
          { answer: "take" },
          { answer: "want"},
          { answer: "have" , correct: "true" },
          { answer: "ask"},
          { answer: "I don’t know"},
        ],
      },
      {
        id: 10,
        type: "radio",
        question: "The people ___ in room 12.",
        answers: [
          { answer: "is" },
          { answer: "am"},
          { answer: "are", correct: "true"},
          { answer: "be"  },
          { answer: "I don’t know"},
        ],
      },
      {
        id: 11,
        type: "radio",
        question: "It’s _____ to seven.",
        answers: [
          { answer: "for" },
          { answer: "at"},
          { answer: "in"},
          { answer: "ten" , correct: "true" },
          { answer: "I don’t know"},
        ],
      },
      {
        id: 12,
        type: "radio",
        question: "I ___ to classical music.",
        answers: [
          { answer: "never to listen" },
          { answer: "listen never"},
          { answer: "never listen", correct: "true"},
          { answer: "don’t never listen" },
          { answer: "I don’t know"},
        ],
      },
      {
        id: 13,
        type: "radio",
        question: "Would you like ___ coffee?",
        answers: [
          { answer: "other" },
          { answer: "another", correct: "true"},
          { answer: "some other"},
          { answer: "more one"  },
          { answer: "I don’t know"},
        ],
      },
      {
        id: 14,
        type: "radio",
        question: "I haven’t ___ this photo before.",
        answers: [
          { answer: "see" },
          { answer: "saw"},
          { answer: "sees"},
          { answer: "seen" , correct: "true" },
          { answer: "I don’t know"},
        ],
      },
      {
        id: 15,
        type: "radio",
        question: "I can’t see. Where are my ___?",
        answers: [
          { answer: "glasses", correct: "true" },
          { answer: "stamps"},
          { answer: "keys"},
          { answer: "lipsticks"  },
          { answer: "I don’t know"},
        ],
      },
      {
        id: 16,
        type: "radio",
        question: "I like ___ in the morning.",
        answers: [
          { answer: "be work" },
          { answer: "work"},
          { answer: "working", correct: "true"},
          { answer: "works"  },
          { answer: "I don’t know"},
        ],
      },
      {
        id: 17,
        type: "radio",
        question: "Thanks for ___.",
        answers: [
          { answer: "all" },
          { answer: "the all"},
          { answer: "everything", correct: "true"},
          { answer: "things"  },
          { answer: "I don’t know"},
        ],
      },
      {
        id: 18,
        type: "radio",
        question: "‘Was Debussy from France?’ ‘Yes, ___.’",
        answers: [
          { answer: "he were" },
          { answer: "was"},
          { answer: "there were"},
          { answer: "he was" , correct: "true" },
          { answer: "I don’t know"},
        ],
      },
      {
        id: 19,
        type: "radio",
        question: "I’m Italian. ___ family are from Venice.",
        answers: [
          { answer: "Our" },
          { answer: "My", correct: "true"},
          { answer: "Her"},
          { answer: "Me"  },
          { answer: "I don’t know"},
        ],
      },
      {
        id: 20,
        type: "radio",
        question: "What ___ do tomorrow?",
        answers: [
          { answer: "are you going" },
          { answer: "you going"},
          { answer: "are you going to", correct: "true"},
          { answer: "do you go to"  },
          { answer: "I don’t know"},
        ],
      },
      {
        id: 21,
        type: "radio",
        question: "Can I pay ___ credit card?",
        answers: [
          { answer: "by" , correct: "true" },
          { answer: "in"},
          { answer: "on"},
          { answer: "with"  },
          { answer: "I don’t know"},
        ],
      },
      {
        id: 22,
        type: "radio",
        question: "This isn’t my money. It’s ___.",
        answers: [
          { answer: "to you" },
          { answer: "the yours"},
          { answer: "your"},
          { answer: "yours" , correct: "true" },
          { answer: "I don’t know"},
        ],
      },
      {
        id: 23,
        type: "radio",
        question: "Tonight’s dinner is ___ than last night’s.",
        answers: [
          { answer: "more good" },
          { answer: "gooder"},
          { answer: "better", correct: "true"},
          { answer: "more better"  },
          { answer: "I don’t know"},
        ],
      },
      {
        id: 24,
        type: "radio",
        question: "They didn’t ___ the tickets.",
        answers: [
          { answer: "booking" },
          { answer: "booked"},
          { answer: "book", correct: "true"},
          { answer: "not book"  },
          { answer: "I don’t know"},
        ],
      },
      {
        id: 25,
        type: "radio",
        question: "They’re ___.",
        answers: [
          { answer: "bigs cars" },
          { answer: "cars bigs"},
          { answer: "big cars" , correct: "true"},
          { answer: "bigs car" },
          { answer: "I don’t know"},
        ],
      },
      {
        id: 26,
        type: "radio",
        question: "___ the time?",
        answers: [
          { answer: "What’s", correct: "true" },
          { answer: "What is it"},
          { answer: "What"},
          { answer: "What it is"  },
          { answer: "I don’t know"},
        ],
      },
      {
        id: 27,
        type: "radio",
        question: "She ___ to the gym every day.",
        answers: [
          { answer: "gets" },
          { answer: "goes", correct: "true"},
          { answer: "has"},
          { answer: "does"  },
          { answer: "I don’t know"},
        ],
      },
      {
        id: 28,
        type: "radio",
        question: "I ___ do my homework last night.",
        answers: [
          { answer: "not could" },
          { answer: "didn’t can"},
          { answer: "couldn’t", correct: "true" },
          { answer: "can’t" },
          { answer: "I don’t know"},
        ],
      },
      {
        id: 29,
        type: "radio",
        question: "There ___ telephone in my hotel room.",
        answers: [
          { answer: "wasn’t a", correct: "true" },
          { answer: "weren’t a"},
          { answer: "weren’t any"},
          { answer: "wasn’t some"  },
          { answer: "I don’t know"},
        ],
      },
      {
        id: 30,
        type: "radio",
        question: "He ___ playing the piano.",
        answers: [
          { answer: "are" },
          { answer: "does"},
          { answer: "is" , correct: "true" },
          { answer: "has"},
          { answer: "I don’t know"},
        ],
      },
      {
        id: 31,
        type: "radio",
        question: "He ___ jeans.",
        answers: [
          { answer: "doesn’t usually wear", correct: "true" },
          { answer: "isn’t usually wearing"},
          { answer: "wears usually"},
          { answer: "doesn’t wear usually"  },
          { answer: "I don’t know"},
        ],
      },
      {
        id: 32,
        type: "radio",
        question: "I ___ my new job last week.",
        answers: [
          { answer: "have begun" },
          { answer: "began", correct: "true"},
          { answer: "am begin"},
          { answer: "begin"  },
          { answer: "I don’t know"},
        ],
      },
      {
        id: 33,
        type: "radio",
        question: "There isn’t ___ pasta in the kitchen.",
        answers: [
          { answer: "some" },
          { answer: "many"},
          { answer: "a"},
          { answer: "any" , correct: "true" },
          { answer: "I don’t know"},
        ],
      },
      {
        id: 34,
        type: "radio",
        question: "She ___ to cook for her boyfriend.",
        answers: [
          { answer: "isn’t going", correct: "true" },
          { answer: "isn’t go"},
          { answer: "aren’t going"},
          { answer: "doesn’t go"  },
          { answer: "I don’t know"},
        ],
      },
      {
        id: 35,
        type: "radio",
        question: "The elephant is ___ land animal in the world.",
        answers: [
          { answer: "the bigger" },
          { answer: "the most big"},
          { answer: "biggest"},
          { answer: "the biggest" , correct: "true" },
          { answer: "I don’t know"},
        ],
      },
      {
        id: 36,
        type: "radio",
        question: "___ yesterday?",
        answers: [
          { answer: "You studied" },
          { answer: "Did you studied"},
          { answer: "Did you study", correct: "true"},
          { answer: "Studied you"  },
          { answer: "I don’t know"},
        ],
      },
      {
        id: 37,
        type: "radio",
        question: "James would like ___ basketball.",
        answers: [
          { answer: "playing" },
          { answer: "to play" , correct: "true"},
          { answer: "play"},
          { answer: "to playing" },
          { answer: "I don’t know"},
        ],
      },
      {
        id: 38,
        type: "radio",
        question: "I always ___.",
        answers: [
          { answer: "work hard", correct: "true" },
          { answer: "hard work"},
          { answer: "hardly work"},
          { answer: "work hardly"  },
          { answer: "I don’t know"},
        ],
      },
      {
        id: 39,
        type: "radio",
        question: "We ___ to Canada.",
        answers: [
          { answer: "haven’t be" },
          { answer: "hasn’t been"},
          { answer: "hasn’t be"},
          { answer: "haven’t been" , correct: "true" },
          { answer: "I don’t know"},
        ],
      },
      {
        id: 40,
        type: "radio",
        question: "He ___ follow instructions.",
        answers: [
          { answer: "doesn’t can" },
          { answer: "not can"},
          { answer: "isn’t can"},
          { answer: "can’t" , correct: "true" },
          { answer: "I don’t know"},
        ],
      },
      {
        id: 41,
        type: "radio",
        question: "It ___ when they went out. ",
        answers: [
          { answer: "rained" },
          { answer: "was raining", correct: "true"},
          { answer: "is raining"},
          { answer: "was to rain"  },
          { answer: "I don’t know"},
        ],
      },
      {
        id: 42,
        type: "radio",
        question: "Did you ___ TV last night?",
        answers: [
          { answer: "watch", correct: "true" },
          { answer: "see"},
          { answer: "look at"},
          { answer: "listen"  },
          { answer: "I don’t know"},
        ],
      },
      {
        id: 43,
        type: "radio",
        question: "That’s the hotel ___ we had lunch.",
        answers: [
          { answer: "what" },
          { answer: "where" , correct: "true"},
          { answer: "that"},
          { answer: "which" },
          { answer: "I don’t know"},
        ],
      },
      {
        id: 44,
        type: "radio",
        question: "Diana ___ some wine when she went to France.",
        answers: [
          { answer: "bought", correct: "true" },
          { answer: "buy"},
          { answer: "buys"},
          { answer: "did bought"  },
          { answer: "I don’t know"},
        ],
      },
      {
        id: 45,
        type: "radio",
        question: "I don’t get ___ very well with my brother.",
        answers: [
          { answer: "on"  , correct: "true"},
          { answer: "to"},
          { answer: "from"},
          { answer: "by" },
          { answer: "I don’t know"},
        ],
      },
      {
        id: 46,
        type: "radio",
        question: "Tom always ___ golf on Sundays.",
        answers: [
          { answer: "plays", correct: "true" },
          { answer: "play"},
          { answer: "to play"},
          { answer: "is plays"  },
          { answer: "I don’t know"},
        ],
      },
      {
        id: 47,
        type: "radio",
        question: "Mary ___ a key when she was cleaning her car.",
        answers: [
          { answer: "was finding" },
          { answer: "finded"},
          { answer: "founded"},
          { answer: "found" , correct: "true" },
          { answer: "I don’t know"},
        ],
      },
      {
        id: 48,
        type: "radio",
        question: "___ I worked hard, I didn’t pass the test.",
        answers: [
          { answer: "Although", correct: "true" },
          { answer: "So"},
          { answer: "Because"},
          { answer: "But"  },
          { answer: "I don’t know"},
        ],
      },
      {
        id: 49,
        type: "radio",
        question: "My parents ___ to stay with us next week.",
        answers: [
          { answer: "comes" },
          { answer: "coming"},
          { answer: "is coming"},
          { answer: "are coming" , correct: "true" },
          { answer: "I don’t know"},
        ],
      },
      {
        id: 50,
        type: "radio",
        question: "Come on, it’s time ___.",
        answers: [
          { answer: "to go", correct: "true" },
          { answer: "going"},
          { answer: "we go"},
          { answer: "go"  },
          { answer: "I don’t know"},
        ],
      },
      {
        id: 51,
        type: "radio",
        question: "Can you look ___ my dog this weekend?",
        answers: [
          { answer: "with" },
          { answer: "away"},
          { answer: "up"},
          { answer: "after" , correct: "true" },
          { answer: "I don’t know"},
        ],
      },
      {
        id: 52,
        type: "radio",
        question: "Who ___ the answer to this question?",
        answers: [
          { answer: "knows", correct: "true"  },
          { answer: "know"},
          { answer: "does know"},
          { answer: "does knows" },
          { answer: "I don’t know"},
        ],
      },
      {
        id: 53,
        type: "radio",
        question: "When I got to work I remembered that ___ my mobile at home.",
        answers: [
          { answer: "I’d leave" },
          { answer: "I was leaving"},
          { answer: "I’d left", correct: "true"},
          { answer: "I left"  },
          { answer: "I don’t know"},
        ],
      },
      {
        id: 54,
        type: "radio",
        question: "My father ___ be a builder.",
        answers: [
          { answer: "used to" , correct: "true" },
          { answer: "was"},
          { answer: "use to"},
          { answer: "did use to" },
          { answer: "I don’t know"},
        ],
      },
      {
        id: 55,
        type: "radio",
        question: "I haven’t tidied my office ___.",
        answers: [
          { answer: "just" },
          { answer: "already"},
          { answer: "yet" , correct: "true"},
          { answer: "since" },
          { answer: "I don’t know"},
        ],
      },
      {
        id: 56,
        type: "radio",
        question: "I can sing, but not as ___ as my sister.",
        answers: [
          { answer: "well", correct: "true"  },
          { answer: "good"},
          { answer: "best"},
          { answer: "better" },
          { answer: "I don’t know"},
        ],
      },
      {
        id: 57,
        type: "radio",
        question: "You took my money! Give ___!",
        answers: [
          { answer: "it back", correct: "true" },
          { answer: "back it"},
          { answer: "it"},
          { answer: "them back"  },
          { answer: "I don’t know"},
        ],
      },
      {
        id: 58,
        type: "radio",
        question: "Richard isn’t very good ___.",
        answers: [
          { answer: "to dance" },
          { answer: "at dancing", correct: "true"},
          { answer: "dancing"},
          { answer: "dance"  },
          { answer: "I don’t know"},
        ],
      },
      {
        id: 59,
        type: "radio",
        question: "I’m sure Canada isn’t as big ___ Russia.",
        answers: [
          { answer: "as", correct: "true" },
          { answer: "than"},
          { answer: "to"},
          { answer: "like"  },
          { answer: "I don’t know"},
        ],
      },
      {
        id: 60,
        type: "radio",
        question: "It’s important ___ too much alcohol.",
        answers: [
          { answer: "not to drinking" },
          { answer: "not to drink" , correct: "true"},
          { answer: "not drink"},
          { answer: "not drinks" },
          { answer: "I don’t know"},
        ],
      },
      {
        id: 61,
        type: "radio",
        question: "We ___ take a map.",
        answers: [
          { answer: "should" , correct: "true" },
          { answer: "should might to"},
          { answer: "might to"},
          { answer: "should to" },
          { answer: "I don’t know"},
        ],
      },
      {
        id: 62,
        type: "radio",
        question: "___ dinner in a restaurant today.",
        answers: [
          { answer: "I have " },
          { answer: "I having"},
          { answer: "I’m having" , correct: "true"},
          { answer: "I’m to have" },
          { answer: "I don’t know"},
        ],
      },
      {
        id: 63,
        type: "radio",
        question: "Cameras aren’t allowed here – you______take photos.",
        answers: [
          { answer: "mustn’t", correct: "true" },
          { answer: "don’t have to"},
          { answer: "must not to"},
          { answer: "have to"  },
          { answer: "I don’t know"},
        ],
      },
      {
        id: 64,
        type: "radio",
        question: "Pam ___ eat cheese, but she does now.",
        answers: [
          { answer: "didn’t used to" },
          { answer: "did use"},
          { answer: "didn’t use to", correct: "true"},
          { answer: "wasn’t to"  },
          { answer: "I don’t know"},
        ],
      },
      {
        id: 65,
        type: "radio",
        question: "We ___ late and the game had already started",
        answers: [
          { answer: "arrived", correct: "true" },
          { answer: "had arrived"},
          { answer: "didn’t arrive"},
          { answer: "were arriving"  },
          { answer: "I don’t know"},
        ],
      },
      {
        id: 66,
        type: "radio",
        question: "Your diet is terrible. You don’t eat ___.",
        answers: [
          { answer: "many vegetables" },
          { answer: "enough vegetables" , correct: "true"},
          { answer: "vegetables enough"},
          { answer: "many vegetable" },
          { answer: "I don’t know"},
        ],
      },
      {
        id: 67,
        type: "radio",
        question: "If we had the money, we ___ get a taxi.",
        answers: [
          { answer: "will can" },
          { answer: "can"},
          { answer: "could", correct: "true"},
          { answer: "would can"  },
          { answer: "I don’t know"},
        ],
      },
      {
        id: 68,
        type: "radio",
        question: "___ my best friend since 1999.",
        answers: [
          { answer: "I’ve known", correct: "true" },
          { answer: "I knew"},
          { answer: "I’m knowing"},
          { answer: "I know"  },
          { answer: "I don’t know"},
        ],
      },
      {
        id: 69,
        type: "radio",
        question: "You _____ the new cafe in town. The coffee’s terrible.",
        answers: [
          { answer: "aren’t like" },
          { answer: "won’t like", correct: "true"},
          { answer: "isn’t like"},
          { answer: "won’t liking"  },
          { answer: "I don’t know"},
        ],
      },
      {
        id: 70,
        type: "radio",
        question: "There’s always a lot of traffic going ___ the bridge.",
        answers: [
          { answer: "over" , correct: "true" },
          { answer: "in"},
          { answer: "at"},
          { answer: "through" },
          { answer: "I don’t know"},
        ],
      },
      {
        id: 71,
        type: "radio",
        question: "This road was built ___ the Romans.",
        answers: [
          { answer: "of" },
          { answer: "for"},
          { answer: "by", correct: "true"},
          { answer: "with"  },
          { answer: "I don’t know"},
        ],
      },
      {
        id: 72,
        type: "radio",
        question: "Michelangelo ___ some of his best works in Rome.",
        answers: [
          { answer: "painted", correct: "true" },
          { answer: "was painted"},
          { answer: "is painting"},
          { answer: "has painted"  },
          { answer: "I don’t know"},
        ],
      },
      {
        id: 73,
        type: "radio",
        question: "She told me ___ number, but I can’t remember it.",
        answers: [
          { answer: "mine" },
          { answer: "him"},
          { answer: "her" , correct: "true"},
          { answer: "hers" },
          { answer: "I don’t know"},
        ],
      },
      {
        id: 74,
        type: "radio",
        question: "You eat ___ chocolate - you really should give up.",
        answers: [
          { answer: "too much", correct: "true" },
          { answer: "enough"},
          { answer: "very many"},
          { answer: "much"  },
          { answer: "I don’t know"},
        ],
      },
      {
        id: 75,
        type: "radio",
        question: "How ___ your name?",
        answers: [
          { answer: "is it pronounced" },
          { answer: "you pronounce"},
          { answer: "do you pronounce", correct: "true"},
          { answer: "to pronounce"  },
          { answer: "I don’t know"},
        ],
      },
      {
        id: 76,
        type: "radio",
        question: "I ___ come to the party tonight.",
        answers: [
          { answer: "might not", correct: "true" },
          { answer: "don’t might"},
          { answer: "don’t"},
          { answer: "not to"  },
          { answer: "I don’t know"},
        ],
      },
      {
        id: 77,
        type: "radio",
        question: "I’m really tired - I only got ___ hours’ sleep.",
        answers: [
          { answer: "not many" },
          { answer: "a few", correct: "true"},
          { answer: "a little"},
          { answer: "few"  },
          { answer: "I don’t know"},
        ],
      },
      {
        id: 78,
        type: "radio",
        question: "Your papers are on the floor. Why don’t you ___?",
        answers: [
          { answer: "pick them up", correct: "true" },
          { answer: "pick up to them"},
          { answer: "pick them"},
          { answer: "pick up them"  },
          { answer: "I don’t know"},
        ],
      },
      {
        id: 79,
        type: "radio",
        question: "If you take your time, ___ the right decision.",
        answers: [
          { answer: "you make" },
          { answer: "you’d make"},
          { answer: "you’ll make", correct: "true"},
          { answer: "you’re making"  },
          { answer: "I don’t know"},
        ],
      },
      {
        id: 80,
        type: "radio",
        question: "I ___ the museum because I hadn’t brought a map.",
        answers: [
          { answer: "couldn’t find", correct: "true"  },
          { answer: "couldn’t to find"},
          { answer: "can’t find"},
          { answer: "hadn’t found" },
          { answer: "I don’t know"},
        ],
      },
    ],
  };