import React, { useState } from "react";

const AnswerText = () => {
const [answer, setAnswer] = useState("");

const handleChange = (e) => {
    setAnswer(e.target.value);
}

  return (
    <div>
      <input type="text" value={answer} onChange={handleChange}></input>
    </div>
  );
};

export default AnswerText;
