import React from "react";
import "./App.css";
import { Quiz } from "./components/Quiz/Quiz";
import { quiz } from './quizData';

function App() {
  return (
    <div className="App">
      <h1>Test poziomujący dla grupy na poziomie: <br/>
POCZĄTKUJĄCY (ELEMENTARY) LUB POŚREDNI (PRE-INTERMEDIATE)</h1>
      <Quiz quiz={quiz} />
    </div>
  );
}

export default App;
