import React, { useState } from "react";
import { Question } from "./Question";
import Progress from "./Progress";
import CustomerData from "./CustomerData";
import SendingResults from "./SendingResults";

export const Quiz = ({ quiz }) => {
  const [answers, setAnswers] = useState({});
  const [currentAnswer, setCurrentAnswer] = useState("");
  const [activeQuestion, setActiveQuestion] = useState(0);
  const [quizState, setQuizState] = useState("INFO");
  const [customerData, setCustomerData] = useState({ name: "", email: "" });

  const returnData = (custData) => {
    setCustomerData(custData);
    setQuizState("QUESTIONS");
  };

  const submitAnswer = () => {
    setAnswers((prevState) => {
      return {
        ...prevState,
        [quiz.questions[activeQuestion].id]: currentAnswer,
      };
    });
    setCurrentAnswer("");

    if (activeQuestion < quiz.questions.length - 1) {
      setActiveQuestion(activeQuestion + 1);
    } else {
      setQuizState("RESULTS");
    }
  };

  const markQuiz = () => {
    const totalQuestions = quiz.questions.length;
    const correctAnswers = quiz.questions.reduce((acc, q) => {
      if (q.answers.find((a) => a.correct === "true").answer === answers[q.id])
        acc++;
      return acc;
    }, 0);
    const questions = quiz.questions.map((question) => {
      return {
        id: question.id,
        question: question.question,
        result:
          question.answers.find((a) => a.correct === "true").answer ===
          answers[question.id]
            ? "Correct"
            : "Incorrect",
        answerGiven: answers[question.id],
        correctAnswer: question.answers.find((a) => a.correct === "true")
          .answer,
      };
    });

    return {
      totalQuestions,
      correctAnswers,
      questions,
    };
  };

  const renderResults = () => {
    const { totalQuestions, correctAnswers, questions } = markQuiz();

    return (
      <div className="results">
        <div>
          <h2>Liczba poprawnych odpowiedzi: {correctAnswers} z {totalQuestions} (
          {Math.round((correctAnswers / totalQuestions) * 100)}%).</h2>
        </div>
        <SendingResults
          totalQuestions={totalQuestions}
          correctAnswers={correctAnswers}
          questions={questions}
          customerData={customerData}
        />
      </div>
    );
  };

  const renderQuiz = () => {
    switch (quizState) {
      case "INFO":
        return (
          <>
            <h2 className="u-padding-10 center">
              Uprzejmie proszę o podanie swoich danych. Po wykonaniu testu
              dostaniesz swój procentowy wynik na email. Podam też dokładny
              poziom oraz dane kursu najlepszego dla Ciebie.
            </h2>
            <h2 className="u-padding-10 center">
              Jeśli nie znasz
              odpowiedzi, wybierz opcję 5 – I don’t know
            </h2>
            <h2 className="u-padding-10 center">
              Powodzenia!
            </h2>

            <div className="container">
              <CustomerData returnData={returnData} />
            </div>
          </>
        );
      case "RESULTS":
        return (
          <>
            <h1>Koniec testu</h1>
            {renderResults()}
          </>
        );
      case "QUESTIONS":
        return (
          <>
            <Progress
              questionId={activeQuestion}
              totalQuestions={quiz.questions.length}
            />
            <Question
              question={quiz.questions[activeQuestion]}
              updateAnswer={submitAnswer}
              currentAnswer={currentAnswer}
              setCurrentAnswer={setCurrentAnswer}
            />
          </>
        );
      default:
        return null;
    }
  };

  return <div>{renderQuiz()}</div>;
};
