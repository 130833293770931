import React from "react";

const AnswerRadio = ({ id, answers, answer, setAnswer }) => {
  const handleChange = (event) => {
    setAnswer(event.target.value);
  }

  return (
    <div>
      {answers.map((ans) => {
        return (
          <div key={ans.answer}>
            <label className="answer__radio">
            <input className="answer__radio-button" type="radio" name={id} value={ans.answer} checked={ans.answer === answer} onChange={handleChange} />
            <p className="answer__radio-text">{ans.answer}</p>
            </label>
          </div>
        );
      })}
    </div>
  );
};

export default AnswerRadio;
