import React from 'react';

const Progress = ({ questionId, totalQuestions }) => {
    return (
        <div className="progress">
            <h2>Pytanie {questionId + 1} z { totalQuestions }</h2>
        </div>
    );
};

export default Progress;