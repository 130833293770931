import React, { useState, useEffect } from "react";

const CustomerData = ({ returnData }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [gdprFlag, setGdprFlag] = useState(false);
  const [submitEnabled, setSubmitEnabled] = useState(false);

  useEffect(() => {
    if (firstName && lastName && email && mobile && gdprFlag) {
      setSubmitEnabled(true);
    } else {
      setSubmitEnabled(false);
    }
  }, [firstName, lastName, email, mobile, gdprFlag]);

  const onSubmit = (e) => {
    e.preventDefault();
    const date = new Date();
    returnData({
      firstName,
      lastName,
      email,
      mobile,
      gdprFlag,
      testStarted: date.getTime(),
    });
  };

  return (
    <form className="user-data-form" onSubmit={onSubmit}>
      <div className="formElement">
        <label htmlFor="firstName">Imię</label>
        <input
          className="form__input"
          type="text"
          name="firstName"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
      </div>
      <div className="formElement">
        <label>Nazwisko</label>
        <input
          className="form__input"
          type="text"
          name="lastName"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />
      </div>
      <div className="formElement">
        <label>Twój adres email</label>
        <input
          className="form__input"
          type="email"
          name="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      <div className="formElement">
        <label>Telefon</label>
        <input
          className="form__input"
          type="text"
          name="mobile"
          value={mobile}
          onChange={(e) => setMobile(e.target.value)}
        />
      </div>
      <div className="formElement">
        <div className="form__gdpr-wrapper">
          <input
            className="form__checkbox"
            type="checkbox"
            name="gdpr"
            checked={gdprFlag}
            onChange={(e) => setGdprFlag(e.target.value)}
          />
          <p className="form__gdpr-text">
            Wyrażam zgodę na przetwarzanie moich danych osobowych w celu
            dopasowania i dostarczenia wyników testu oraz kursu języka
            angielskiego na zasadach zgodnych z ustawą o ochronie danych
            osobowych GDPR
          </p>
        </div>
      </div>
      <button className="btn" disabled={!submitEnabled} type="submit">Dalej</button>
    </form>
  );
};

export default CustomerData;
