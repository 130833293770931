import React from "react";

import AnswerText from './AnswerText';
import AnswerRadio from './AnswerRadio';
import AnswerCheckbox from './AnswerCheckbox';

export const Question = ({ question, currentAnswer, updateAnswer, setCurrentAnswer }) => {
  const answers = ({ type, answers, id }) => {
    switch (type) {
      case "text":
        return <AnswerText answers={answers} answer={currentAnswer} setAnswer={setCurrentAnswer} />;
      case "checkbox":
        return <AnswerCheckbox answers={answers} answer={currentAnswer} setAnswer={setCurrentAnswer} />;
      case "radio":
        return <AnswerRadio id={id} answers={answers} answer={currentAnswer} setAnswer={setCurrentAnswer} />;
      default:
        return <input type="text"></input>;
    }
  };
  return (
    <div className="question">
      <div>{question.question}</div>
      <div className="question__answer">{answers(question)}</div>
    { currentAnswer && <button className="btn" type="button" onClick={(e) => updateAnswer()}>Dalej</button>}
    </div>
  );
};
