import React from "react";

const AnswerCheckbox = ({ answers, answer, setAnswer }) => {

  const handleChange = (event) => {
    // setAnswer(event.target.value);
    console.log(event.target.value);
  }

  return (
    <div>
      {answers.map((ans) => {
        return (
          <div>
            <input key={ans.answer} type="checkbox" checked={ans.answer === answer} onChange={handleChange}/>
            {ans.answer}
          </div>
        );
      })}
    </div>
  );
};

export default AnswerCheckbox;
