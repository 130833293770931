import React, { useEffect, useState } from "react";
import RecommendedCourse from "./RecommendedCourse";

const SendingResults = ({
    totalQuestions,
    correctAnswers,
    questions,
    customerData,
}) => {
    const [sendState, setSendState] = useState("SENDING");
    const score = Math.round((correctAnswers / totalQuestions) * 100);

    const getRecommendation = (score) => {
        if (score <= 45) {
            return {
                template: "recommendation",
                courseName: "Elementary",
                courseLink:
                    "https://courses.totalenglish.co.uk/kurs-elementary-1-2-3",
                courseImage:
                    "https://te-initial-assessment.web.app/images/1.jpg",
                text: "",
            };
        }

        if (score >= 46 && score <= 90) {
            return {
                template: "recommendation",
                courseName: "Pre-Intermediate",
                courseLink:
                    "https://courses.totalenglish.co.uk/poziom-posredni-1-2-3",
                courseImage:
                    "https://te-initial-assessment.web.app/images/2.jpg",
                text: "",
            };
        }

        if (score >= 91) {
            return {
                template: "contactus",
                text: "Niestety w tej chwili nie mamy kursu na tym poziomie. Skontaktuj się ze mną przez email: info@totalenglish.co.uk",
                courseName: "poziom powyżej Pre-Intermediate",
                courseLink: "",
                courseImage: "",
            };
        }
    };

    useEffect(() => {
        async function fetchData() {
            const databaseData = {
                customer: customerData,
                summary: {
                    totalQuestions,
                    correctAnswers,
                },
                questions,
            };

            const { courseName, courseLink, courseImage, template, text } =
                getRecommendation(score);

            const emailData = {
                to: customerData.email,
                score,
                courseImage,
                courseName,
                courseLink,
                template,
                text,
            };

            await fetch("/api/addResults", {
                method: "POST",
                body: JSON.stringify(databaseData),
            });
            await fetch("/api/sendResults", {
                method: "POST",
                body: JSON.stringify(emailData),
            });
            setSendState("SENT");
        }
        fetchData();
    }, []);

    return (
        <div>
            {sendState === "SENT" ? (
                <>
                    <h2 className="u-center u-padding-20">
                        Gratulacje! Mamy dla Ciebie odpowiedni kurs. Zacznij się
                        uczyć już teraz!
                    </h2>
                    <p className="u-center u-padding-20">Procentowy wynik testu poziomującego i proponowany kurs dostaniesz też na podany adres email. Sprawdź folder spam i folder oferty.</p>
                    <RecommendedCourse score={score} />
                </>
            ) : (
                <p>Poczekaj 5 sekund. Obliczamy wynik testu.</p>
            )}
        </div>
    );
};

export default SendingResults;
