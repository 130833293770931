import React from "react";

const RecommendedCourse = ({ score }) => {
  const renderRecommendation = () => {
    if (score <= 45) {
      return (
        <div className="recommended-course">
          <div className="recommended-course__content">
            <h3>Wstępna ocena: Elementary</h3>
            <a href="https://courses.totalenglish.co.uk/kurs-elementary-1-2-3">
              <button className="btn">KUP KURS</button>
            </a>
          </div>
          <img
            className="recommended-course__image"
            src="/images/1.jpg"
            alt="Elementary course"
          />
        </div>
      );
    }
    if (score >= 46 && score <= 90) {
      return (
        <div className="recommended-course">
          <div className="recommended-course__content">
            <h3>Wstępna ocena: Pre-Intermediate</h3>
            <a href="https://courses.totalenglish.co.uk/poziom-posredni-1-2-3">
              <button className="btn">KUP KURS</button>
            </a>
          </div>
          <img
            className="recommended-course__image"
            src="/images/2.jpg"
            alt="Pre-Intermediate course"
          />
        </div>
      );
    }
    if (score >= 91) {
      return (
        <div className="recommended-course">
          <div className="recommended-course__content">
            <h3>Wstępna ocena: poziom powyżej Pre-intermediate</h3>
          </div>
          <div>
            <h2 style={{ textAlign: "center" }}>
              Niestety w tej chwili nie mamy kursu na tym poziomie. <br/>Skontaktuj
              się ze mną przez email:
              info@totalenglish.co.uk
            </h2>
          </div>
        </div>
      );
    }
  };
  return renderRecommendation();
};

export default RecommendedCourse;
